<template>
  <section class="footer">
    <div class="footer__grid">
      <div class="footer__block footer__block--main">
        <logo-zionesport class="footer__logo" color="currentColor" />
        <ul class="footer__content">
          <li>
            <b class="text--highlighted">
              Somos buenos porque somos los mejores
            </b>
            <br>
            &reg; Aplicaciones Deportivas Zione, SA de CV
          </li>
          <li>
            <fa-icon :icon="['fas', 'headset']" size="lg" />
             &nbsp;+52 81 1968 8075
          </li>
          <li>
            <a href="https://wa.me/528126276126?text=Hola%20zione.sport%2C%20" target="_whatsapp">
              <fa-icon :icon="['fab', 'whatsapp']" size="lg" />
              &nbsp;+52 81 2627 6126  
            </a>
          </li>
          <li>
          	<a href="https://www.facebook.com/zione.sport/" target="_facebook">
          		<fa-icon :icon="['fab', 'facebook-square']" size="lg" />
          		&nbsp;&nbsp;/zione.sport
          	</a>
          </li>
          <li>
          	<a href="https://www.instagram.com/zione.sport/" target="_instagram">
          		<fa-icon :icon="['fab', 'instagram']" size="lg" />
              &nbsp;&nbsp;/zione.sport
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__block">
        <h3 class="footer__headline">Deportes</h3>
        <ul class="footer__content">
          <li>Fútbol Soccer</li>
          <li>Basquetbol</li>
          <li>F. Americano y Tocho</li>
          <li>Voliebol</li>
          <li>Beisbol y Softbol</li>
          <li>Raqueta</li>
        </ul>
      </div>
      <div class="footer__block">
        <h3 class="footer__headline">Productos</h3>
        <ul class="footer__content">
        	<li>
            <router-link :to="{ name: 'torneos' }">
              zione.sport Torneos
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'coaches' }">
              zione.sport Coaches
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'saldos' }">
              zione.sport Saldos
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'apps' }">
              zione.sport Apps
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'apps' }">
              zione.sport App Premium
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'apps' }">
              zione.sport Results
            </router-link>
          </li>
        </ul>
      </div>
      <div class="footer__block">
        <h3 class="footer__headline">Empresa</h3>
        <ul class="footer__content">
        	<li>
            <router-link :to="{ name: 'contactUs' }">
              <b>Conctacto con Ventas</b>
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'about' }">
              Acerca de Nosotros
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'faq' }">
             Preguntas frecuentes
            </router-link>
          </li>
        	<li>
            <router-link :to="{ name: 'joinUs' }">
              <b>Trabaja en zione.sport</b>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'privacy' }">
              Aviso de privacidad
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
  import logoZionesport from "@/components/LogoZionesportH.vue";
  export default {
    name: "SiteFooter",
    components: {
      logoZionesport
    }
  };
</script>

<style scoped>

.footer {
  position: relative;
  width: 100vw;
  margin-top: 10rem;
  background-color: #ffffff;
  padding: 4rem;
  background-color: rgba(255, 255, 255, .33);
}

.footer__grid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: .5rem;
}

.footer__block {
  font-family: 'Open Sans', Helvetica, sans-serif;
  text-align: left;
}

.footer__logo {
  height: 32px;
  color: var(--secondary-color) /* pass var() color to component */
}

.footer__headline {
  margin: 0 0 1rem 0;
  line-height: 32px;
  font-size: .75rem;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.footer__content {
  margin-top: 1rem;
  font-size: .825rem;
}

.footer__content li {
  line-height: 1.5;
  padding: 0 0 1.125em;
  color: var(--secondary-color)
}

.footer__content a {
	color: inherit;
	text-decoration: none;
}

.footer__content a:hover {
	color: var(--primary-color);
}

@media screen and (max-width:640px) {

  .footer {
    padding: 2rem;
  }

  .footer__grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .xxfooter__block:not(.footer__block--main) {
    display: none;
  }
}

@media screen and (min-width:461px) and (max-width:990px) {

  .footer {
    padding: 2rem;
  }

  .footer__grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .footer__block--main {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .footer__block--main .footer__content {
    font-size: 1rem;
  }

  .xxfooter__block:not(.footer__block--main) {
    display: none;
  }
}
</style>

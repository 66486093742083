<template>
	<ul class="feature-list">
		<li class="feature-list__item" v-for="(item, index) in items" :key="index">
			<fa-icon :icon="['fas', 'check-circle']" class="feature-list__icon" /> 
			<span v-markdown:text>{{ item }}</span>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped>

	.feature-list {
		width: 100%;
		font-size: .8125rem;
		padding: .5rem;
	}

	.feature-list__item {
		text-align: left;
		padding: .33em 0;
		color: inherit;
	}

	.feature-list__icon {
		margin-right: .5em;
		color: var(--plan-color);
		font-size: .9em;
	}

</style>
<template>
    <button class="base-button" @click="goHome">
        <slot>{{ label }}</slot>
    </button>
</template>

<script>
  export default {
    name: "BaseButton",
    props: {
      options: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      label() {
        return this.options.label || "...";
      }
    },
    methods: {
      goHome() {
        if (this.options?.routeName)
          return this.$router.push({ name: this.options.routeName });
      }
    }
  }
</script>

<style scoped>
.base-button {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  background-color: var(--primary-color);
  padding: .75em 2.75em;
  color: var(--foreground-text-color);
  border-radius: 2rem;
  /*box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);*/
  cursor: pointer;
  text-decoration: none;
  border: none;
  outline-width: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
import content from "@/content.json";
const metaInfo = content.metaInfo;

const hasMetaInfo = routeName => (metaInfo[routeName]) ? true : false;

/*
const createMetaTag = metaTag => {
	const tag = document.createElement('meta')
	Object.keys(metaTag).forEach(key => {
		tag.setAttribute(key, metaTag[key])
	})
	tag.setAttribute('data-zione-sport', '')
	return tag
}
*/

const metaHandler = to => {

	const metadata = hasMetaInfo(to.name)
		? metaInfo[to.name]
		: metaInfo.default

	if (metadata.title) {
		document.title = `${ metadata.title }${ hasMetaInfo(to.name) ? ' - zione.sport' : '' }`
	}

	if (metadata.description) {
		document.head.querySelector('meta[name="description"]').content = metadata.description;
	}

	if (metadata.og) {
		metadata.og.forEach(og => {
			let tag = document.head.querySelector(`meta[property="${ og.property }"]`);
			if (tag) {
				tag.content = og.content;
			}
		});
	}

	// caninical url
	var hostName = process.env.NODE_ENV == 'production'
		? "https://www.zionesport.com"
		: `${ location.protocol }//${ location.host }`;
	document.head.querySelector('link[rel="canonical"]').href = `${ hostName }${ to.path }`;
	document.head.querySelector('meta[property="og:url"]').content = `${ hostName }${ to.fullPath }`;

	/*
	Array.from(document.querySelectorAll('[data-zione-sport]')).map(el => el.parentNode.removeChild(el));
	if (metadata.meta) {
		metadata.meta
			.map(metaTag => createMetaTag(metaTag))
			.forEach(tag => document.head.appendChild(tag));
	}
	*/
}

export default metaHandler;
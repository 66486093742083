<template>
	<figure class="mockup-frame" :class="$responsive">
		<div class="mockup-frame__header">
			<span class="mockup-frame__circle" v-for="c in 3" :key="c"></span>
		</div>
		<slot></slot>	
	</figure>
</template>

<style scoped>
.mockup-frame {
	--header-height: 1.75rem;
	position: relative;
	background-color: #fff;
	border-radius: 1.125rem;
	margin: 1rem auto 0;
	padding: calc(var(--header-height) + .33rem) .66rem .33rem;
	box-shadow: 0 7px 20px 0 rgba(109,117,141,.2);
	overflow: hidden;
	width: 90%;
}

.mockup-frame.mobile {
	width: 100%;
}

.mockup-frame__header {
	position: absolute;
	left: 0;
	top: 0;
	border-bottom: 1px solid #f3f4f5;
	width: 100%;
	height: var(--header-height);
	display: flex;
	align-items: center;
	column-gap: .33rem;
	padding-left: .825rem;
}

.mockup-frame__circle {
	width: .55rem;
	height: .55rem;
	border-radius: .55rem;
	background-color: #e6e7e8;
	display: inline-block;
}
</style>
<template>
	<div class="mobile-menu">
		<nav class="mobile-nav">
			<logo-zionesport class="mobile-nav__logo" @click="goHome"/>
			<span class="mobile-nav__hamburger" @click="showMenuSlide = true">
				<fa-icon :icon="['fas', 'bars']" />	
			</span>
		</nav>
		<transition name="slide">
			<menu-slide v-show="showMenuSlide" @close="closeSlide"></menu-slide>
		</transition>
	</div>	
</template>

<script>
	import logoZionesport from "@/components/LogoZionesportH.vue";
	import menuSlide from "@/components/MobileMenuSlide.vue";

	export default {
		name: "MobileMenu",
		components: {
			logoZionesport,
			menuSlide
		},
		data() {
			return {
				showMenuSlide: false
			}
		},
		methods: {
			goHome() {
				return this.$router.push('/');
			},
			closeSlide() {
				this.showMenuSlide = false;
			}
		}
	}
</script>

<style scoped>
.mobile-menu {
	--nav-height: 65px;
}

.slide-enter-active,
.slide-leave-active {
	transition: all 0.2s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
	transform: translateX(100vw);
}

.mobile-nav {
	display: flex;
	width: 100vw;
	height: var(--nav-height);
	justify-content: space-between;
	align-items: center;
	padding: 0 1.475rem 0 .875rem;
}

.mobile-nav__logo {
	height: 65%;
	cursor: pointer;
}

.mobile-nav__hamburger {
	font-size: 1.66rem;
	opacity: .66;
}
</style>
<template>
	<div class="main-wrapper">
		<site-header></site-header>
		<transition name="view" mode="out-in">
			<router-view></router-view>
		</transition>
		<!-- recommended
		<router-view v-slot="{ Component }">
			<transition name="view" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
		-->
		<site-footer></site-footer>	
	</div>
</template>

<script>
	import siteHeader from "@/components/SiteHeader.vue";
	import siteFooter from "@/components/SiteFooter.vue";

	export default {
		components: {
			siteHeader,
			siteFooter
		}
	}
</script>

<style>
	.view-enter-active,
	.view-leave-active {
		transition: all 0.5s ease;
	}

	.view-enter-from,
	.view-leave-to {
		opacity: 0;
	}
</style>
/* custom directives */
import { highlightedText, boldText, newLine } from "@/utils.js";

/* custom markdown-text */
/* args: title, text */
const MarkDown = {
	beforeMount(el, binding) {
		let string = newLine(el.innerText);
		string = (binding.arg === "title")
			? highlightedText(string)
			: boldText(string);
		el.innerHTML = string;
	}
}

const Focus = {
	mounted(el, binding) {
		if (binding.value == 0 && !binding.instance.$responsive['sm-view']) {
			el.focus();
		}
	}
}

export { MarkDown, Focus }
<template>
	<div class="modal-backdrop">
		<div class="modal-wrapper">
			<div class="video-modal">
				<div class="video-modal__header">
					<div class="video-modal__title" v-markdown:title>
						{{ title }}
					</div>
					<div class="video-modal__close" @click="closeModal">
						<fa-icon :icon="['fas', 'times']" />
					</div>	
				</div>
				<div class="embed-container">
					<iframe :src="videoSrc" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
				</div>	
			</div>	
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: null
		},
		video: {
			default: null
		}
	},
	data() {
		return {
			videoId: this.video
		}
	},
	computed: {
		videoSrc() {
			return this.videoId ? `https://player.vimeo.com/video/${ this.videoId }` : "about:blank";
		}
	},
	methods: {
		closeModal() { // stop video on close
			this.$emit('close');
		},
		escapeHandler(e) {
	  		if (e.keyCode == 27) this.closeModal();
    	}
	},
	mounted() {
		document.body.classList.add("dont-scroll");
		window.addEventListener('keydown', this.escapeHandler);
	},
	unmounted() {
		window.removeEventListener('keydown', this.escapeHandler);
		document.body.classList.remove("dont-scroll");
	}
}
</script>

<style scoped>

.video-modal {
	width: 100vw;
	max-width: 780px;
	height: auto;
	background-color: #fff;
	overflow: hidden;
	margin: 0 auto;
	border-radius: .33rem;
}

.video-modal__header {
	display: flex;
	align-items: center;
	height: 4rem;
}

.video-modal__title {
	padding-left: 2rem;
	font-size: 1.125rem;
	font-family: 'open sans', Helvetica;
	-webkit-font-smoothing: auto;
}

.video-modal__close {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	color: var(--secondary-color);
	width: 2rem;
	height: 2rem;
	border-radius: 2rem;
	margin-right: 1rem;
	cursor: pointer;
	font-size: 1.125rem;
}

.video-modal__close:hover {
	color: #fff;
	background-color: var(--primary-color);
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width:640px) {
	.modal-backdrop {
		backdrop-filter: saturate(180%) blur(20px);
  	background-color: rgba(255,255,255,0.52);
	}

	.video-modal {
		border-radius: 0;
		background-color: transparent;
	}

	.video-modal__title {
		padding: 0 var(--mobile-padding) 1rem;
	}

	.video-modal__close {
		position: absolute;
		top: 1.5rem;
		right: 1rem;
		font-size: 1.25rem;
	}
}
</style>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Importing the global css file
import "@/main.css"

// Import custom directives and plugins
import { MarkDown } from "@/directives.js"
import responsive from "@/plugins/responsive.js"

// Import fontawesome library / libary storage
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookSquare, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faHeadset, faCheckCircle, faPaperPlane, faCheck, faPlay, faTimes, faBars } from '@fortawesome/free-solid-svg-icons'

library.add(faFacebookSquare, faInstagram, faWhatsapp, faArrowRight, faHeadset, faCheckCircle, faPaperPlane, faCheck, faPlay, faTimes, faBars);

// create and mount main vue instance
createApp(App)
	.component('fa-icon', FontAwesomeIcon) // global
	.use(router)
	.use(responsive)
	.directive('markdown', MarkDown)
	.mount("#app")

//console.log(process.env.NODE_ENV);

<template>
	<div class="play-btn" @click="showVideoModal(true)">
		<span class="play-btn__icon">
			<fa-icon :icon="['fas', 'play']" />	
		</span>
		Mirar el video
	</div>
	<video-modal v-if="screencast.show" :video="screencast.video" :title="screencast.title" @close="showVideoModal(false)"/>
</template>

<script>
	import videoModal from "@/components/VideoModal.vue";
	export default {
		components: {
			videoModal
		},
		data() {
			return {
				screencast: {
					show: false,
					video: '34244394',
					title: 'Si administras una liga, tienes que usar zione.*sport*  🚀'
				}
			}
		},
		methods: {
			showVideoModal(show) {
				this.screencast.show = show;
			}
		},
		mounted() {
			if (this.$route.hash == '#video') {
				this.showVideoModal(true);
			}
		}
	}
</script>
	
<style scoped>
.play-btn	{
	position: relative;
	display: inline-flex;
	align-items: center;
	font-size: 1rem;
	color: var(--primary-color);
	padding: .5em 2em;
	cursor: pointer;
}

.play-btn__icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary-color);
	width: 1.5em;
	height: 1.5em;
	color: var(--foreground-text-color);
	margin-right: 1em;
	border-radius: 50%;
}

.play-btn__icon > * {
	font-size: .5em;
	margin-left: .25em;
}

.play-btn:hover::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: currentColor;
	opacity: .125;
	border-radius: 4em;
	z-index: -1;
}
</style>
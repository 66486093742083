<template>
	<div class="base-paragraph">
		<p class="base-paragraph__content" v-for="(paragraph, index) in paragraphs" :key="index" v-markdown:text>
			{{ paragraph }}
		</p>
	</div>
</template>

<script>
	export default {
		name: "BaseParagraph",
		props: {
			paragraph: {
				type: Object,
				default: () => {}
			}
		},
		computed: {
			paragraphs() {
				const content = this.paragraph.text;
				return Array.isArray(content) ? content : [content];
			}
		}
	}
</script>

<style scoped>
	.base-paragraph__content {
		text-align: left;
		margin: 1rem 0;
		line-height: 1.825;
		color: var(--secondary-color);
		font-size: 1.125rem;
	}
</style>
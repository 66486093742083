<template>
	<section class="page-section">
		<bg-circle v-if="section.circle" :circle="section.circle" />
		<div class="columns-section">
			<div class="columns-section__wrapper">
				<h2 class="columns-section__title" v-if="section.title" v-markdown:title>
					{{ section.title }}
				</h2>
				<p class="columns-section__description" v-if="section.description">
					{{ section.description }}
				</p>
				<div class="columns-section__columns" :style="cols" :class="$responsive">
					<div class="column" v-for="(item, index) in section.columns" :key="index">
						<children-handler :children="item"></children-handler>
					</div>
				</div>
			</div>	
		</div>
	</section>	
</template>

<script>
	import childrenHandler from "@/components/ChildrenHandler.vue";
	import bgCircle from "@/components/BgCircle.vue";

	export default {
		name: "ColumnsSection",
		props: {
			section: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			childrenHandler,
			bgCircle
		},
		computed: {
			cols() {
				let cols = !this.$responsive.desktop
					? this.$responsive.tablet ? 2 : 1
					: this.section.colsNumber || 2;
				return { '--cols': cols };
			}
		}
	}
</script>

<style scoped>

.columns-section {
	display: flex;
	align-items: center;
	min-height: 80vh;
	padding-bottom: 4rem;
}

.columns-section__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.columns-section__description {
	max-width: 800px;
	font-weight: 400;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.6875;
	color: var(--secondary-color);
	margin: 0 auto 1rem;
}

.columns-section__columns { /* safari ios fallback */
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.columns-section__columns { /* override flex mode */
	--gap: 4rem;
	margin-top: 6rem;
	display: grid;
	grid-template-columns: repeat(var(--cols), 1fr);
	grid-template-rows: auto;
	grid-column-gap: var(--gap);
	grid-row-gap: var(--gap);
	row-gap: var(--gap);
}

.column { /* safari ios fallback */
	--col-gap: calc(var(--gap) * (var(--cols) - 1) / var(--cols));
	flex-basis: calc((100% / var(--cols)) - var(--col-gap));
}

</style>
/* utils */

exports.validateEmail = email => {
	const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
	return emailRegex.test(email);
}

exports.highlightedText = str => {
	const reg = /\*([^*]+)\*/g;
	return str.replace(reg, '<span class="text--highlighted">$1</span>');
}

exports.boldText = str => {
	const reg = /\*([^*]+)\*/g;
	return str.replace(reg, '<b>$1</b>');
}

exports.newLine = str => {
	const reg = /\/\//g;
	return str.replace(reg, '<br/>');
}

exports.hexToRgb = hex => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

exports.validateFile = async (file, opts) => {
	if (!opts) {
		throw "configuration is missing";
	}
	const { accepted, limit } = opts;
	if (!file) throw 'Seleccion un archivo';
	if (accepted.indexOf(file.type) < 0) throw 'Formato de archivo no válido';
	if (file.size > limit * 1024 * 1024) throw `Tamaño excede el límite de ${ limit } MB`;
	return
}
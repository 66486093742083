<template>
	<div class="home">
		<div class="bg-wrapper">
			<div class="bg-circle bg-circle--home" :class="$responsive"></div>		
		</div>
		<div class="view-header">
			<div class="home-header" :class="$responsive">
				<zionesport-icon class="home-header__img"/>
				<h1 class="home-header__title">
					Que tus <span class="text--highlighted">clientes</span> se dediquen a <span class="text--highlighted">jugar. . .</span>
					<br>
					<span class="text--highlighted">nosotros</span> nos encargamos del <span class="text--highlighted">resto</span>
				</h1>
				<p class="home-header__subtitle">
					Si administras una liga, tienes que usar zione.sport
				</p>
				<br>
				<screencast-play-btn></screencast-play-btn>
			</div>
		</div>
		<!-- home content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import zionesportIcon from "@/components/ZionesportIcon.vue";
	import screencastPlayBtn from "@/components/ScreencastPlayBtn";
	import sectionHandler from "@/components/SectionHandler";

	export default {
		name: "Home",
		components: {
			zionesportIcon,
			screencastPlayBtn,
			sectionHandler
		},
		data() {
			return {
				sections: content.home
			}
		}
	};
</script>

<style scoped>

.home-header {  
	height: 80vh;
}

.home-header__img {
	width: 120px;
	height: auto;
	margin-bottom: 1.5rem;
}

.home-header__title {
	margin: .25em auto;
	font-size: 2.375rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.mobile .home-header__title {
	font-size: 2rem;
}

.tablet .home-header__title {
	font-size: 2.25rem;
}

.home-header__subtitle {
	margin: 1.5rem 0;
	color: var(--secondary-color);
	font-size: 1.25rem;
}

.bg-wrapper {
  position: relative;
  width: 100%;
}

.bg-circle--home {
	--circle-size: 110vw;
	--bottom: 22vh;
	width: var(--circle-size);
	height: var(--circle-size);
	left: calc((var(--circle-size) - 100vw) / -2);
	top: calc(100vh - var(--bottom) - var(--circle-size) - (80px + 2.125rem));
}

.bg-circle--home.mobile {
	--circle-size: 90vh;
	--bottom: 40vh;
}

.bg-circle--home.tablet {
	--circle-size: 120vh;
	--bottom: 36vh;
}
</style>
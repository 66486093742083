<template>
	<window-frame>
		<div class="image-mockup">
			<img :src="require(`@/assets/${media.image}`)">
		</div>	
	</window-frame>
</template>

<script>
	import windowFrame from "@/components/WindowFrame.vue";
	export default {
		name: "ImageMockup",
		props: {
			media: Object
		},
		components: {
			windowFrame
		}
	}	
</script>

<style scoped>
.image-mockup {
	width: 100%;
	overflow: hidden;
	background-color: #ffffff;
	margin: 0 auto;
}
    
.image-mockup > img {
	display: block;
	width: 100%;
}
</style>
<template>
	<section class="page-section">
		<bg-circle v-if="section.circle" :circle="section.circle" />
		<div class="plans-section">
			<div class="plans-section__wrapper">
				<h2 class="plans-section__title" v-if="section.title" v-markdown:title>
					{{ section.title }}
				</h2>
				<p class="plans-section__description" v-if="section.description">
					{{ section.description }}
				</p>
				<div class="plans-section__plans" :class="$responsive">
					<div class="plan" v-for="(plan, index) in section.plans" :style="color(plan)" :key="index">
						<h3 class="plan__title">
							{{ plan.title }}
						</h3>
						<div class="plan__subtitle">
							{{ plan.subtitle }}
						</div>
						<div class="plan__features">
							<feature-list :items="plan.features"></feature-list>
						</div>
						<div class="plan__price" v-if="plan.price">
							{{ plan.price }}
						</div>	
					</div>
				</div>
				<p class="plans-section__footnote" v-if="section.footnote">
					{{ section.footnote }}
				</p>
			</div>	
		</div>
	</section>	
</template>

<script>
	import featureList from "@/components/FeatureList.vue";
	import bgCircle from "@/components/BgCircle.vue";

	export default {
		name: "PlansSection",
		props: {
			section: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			featureList,
			bgCircle
		},
		methods: {
			color(plan) {
				return { '--plan-color': plan.color };
			}
		}
	}
</script>

<style scoped>

.plans-section {
	display: flex;
	align-items: center;
	min-height: 80vh;
	padding-bottom: 4rem;
}

.plans-section__wrapper {
	width: 100%;
}

.plans-section__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.plans-section__description {
	max-width: 800px;
	font-weight: 400;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.6875;
	color: var(--secondary-color);
	margin: 0 auto 3rem;
}

.plans-section__plans {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	grid-template-rows: auto;
	grid-gap: 1rem;
}

.plans-section__plans.tablet {
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.plans-section__plans.sm-view {
	grid-gap: 2rem;	
}

.plan {
	border:  1px solid var(--plan-color);
	border-radius: 1rem;
	padding: 0 .66rem 0.75rem;
	overflow: hidden;
}

.plan__title {
	margin: 1rem 0 0;
	font-size: 1.25rem;
	letter-spacing: -0.066rem;
	color: var(--plan-color);
}

.plan__subtitle {
	color: var(--secondary-color);
	font-size: .8125rem;
}

.plan__price {
	color: var(--secondary-color);
	display: none;
}

.plans-section__footnote {
	font-size: .8125rem;
	color: var(--secondary-color);
}

</style>
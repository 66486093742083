<template>
	<section class="page-section">
		<bg-circle v-if="section.circle" :circle="section.circle" />
		<div class="content-section" :class="$responsive">
			<div class="content-section__grid" :class="sectionClasses">
				<div class="grid-title">
					<h2 class="content-section__title" v-markdown:title>
						{{ section.title }}
					</h2>
				</div>
				<div class="grid-content">
					<div class="content__item" v-for="(item, index) in section.content" :key="index">
						<children-handler :children="item"></children-handler>
					</div>
				</div>	
			</div>
		</div>
	</section>
</template>

<script>
	import childrenHandler from "@/components/ChildrenHandler.vue";
	import bgCircle from "@/components/BgCircle.vue";

	export default {
		name: "ContentSection",
		props: {
			section: Object
		},
		components: {
			childrenHandler,
			bgCircle
		},
		computed: {
			sectionClasses() {
				return {
					'content-section__grid--inverse': this.section.mode == 'inverse'
				}
			}
		}
	};
</script>

<style scoped>
	.content-section {
		display: flex;
		align-items: center;
		min-height: 50vh;
		padding: 12.5vh 0;
	}

	.content-section.mobile {
		padding: 7.5vh 0 10vh;
	}

	.content-section__grid {
		display: grid;
		grid-template-columns: 1fr 2fr;
		grid-template-areas: "g-title g-content";
		grid-column-gap: 2.25rem;
		width: 100%;
	}

	div.content-section__grid--inverse {
		grid-template-columns: 2fr 1fr;
		grid-template-areas: "g-content g-title";
	}

	.sm-view .content-section__grid {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-template-areas: "g-title" "g-content";
		width: 90%;
		margin: 0 auto 0;
	}

	.grid-title {
		grid-area: g-title;
		text-align: left;
	}

	.grid-content {
		grid-area: g-content;
		text-align: left;
	}
	
	.content-section__title {
		font-size: 2.25rem;
		line-height: 1.125em;
		letter-spacing: -0.066rem;
	}
</style>
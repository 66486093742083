<template>
	<div class="page-section">
		<div class="stack" :class="$responsive">
			<h2 class="stack__legend">
				Nuestros proveedores:
			</h2>
			<div class="stack__grid">
				<div class="stack__item" v-for="(logo, index) in providers" :key="index">
						<img :src="require(`../assets/${logo}`)"/>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
	export default {
		name: "TechStack",
		data() {
			return {
				providers: ['tech-comodo-logo.png', 'tech-armor-logo.jpg', 'tech-aws-logo.png', 'tech-sendgrid-logo.svg', 'tech-oracle-cloud-logo.svg', 'tech-github-logo.svg', 'tech-microsoft-logo.svg', 'tech-vimeo-logo.svg', 'tech-firebase-logo.svg']
			}
		}
	}
</script>

<style scoped>

.stack {
	width: 82%;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 30vh;
}

.stack.sm-view {
	width: 90%;
}

.stack__legend {
	font-weight: 400;
	font-style: normal;
	font-size: 1rem;
	line-height: 1.6875;
	color: var(--secondary-color);
	margin: 0;
}

.stack__grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.stack__item {
	flex-basis: 7.75%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
}

.mobile .stack__item {
	flex-basis: 26%;
}

.stack__item > img {
	max-width: 100%;
	max-height: 70%;
}
</style>
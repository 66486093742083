<template>
	<div class="image-box" :class="$responsive">
		<img :src="require(`@/assets/${imagebox.image}`)">
	</div>
</template>

<script>
	export default {
		name: "ImageBox",
		props: [ 'imagebox' ]
	}	
</script>

<style scoped>
.image-box {
	width: 100%;
	overflow: hidden;
}

.image-box > img {
	display: block;
	max-width: 80%;
	margin: 0 auto;
	max-height: 30vh;
}

.image-box.mobile > img {
	max-height: none;
}
</style>
<template>
	<section class="page-section">
		<bg-circle v-if="section.circle" :circle="section.circle" />
		<div class="default-section" :class="sectionClasses">
			<div class="media">
				<img class="media__image" v-if="mediaType=='image'" :src="require(`@/assets/${section.media.image}`)" />
				<div class="media__video" v-else-if="mediaType=='video'">
					<div class="embed-container">
						<iframe :src="getVideoSrc()" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
					</div>	
				</div>
				<div class="media__mockup" v-else-if="mediaType=='mockup'">
					<image-mock :media="section.media"></image-mock>
				</div>
			</div>
			<div class="content">
				<h3 class="content__legend">{{ section.legend }}</h3>
				<h2 class="content__title" v-markdown:title>
					{{ section.title }}
				</h2>
				<p class="content__description">
					{{ section.content }}
				</p>
				<div class="default-section__pros" v-if="section.pros">
					<pros-list :items="section.pros"></pros-list>
				</div>
				<div v-if="section.link">
					<base-link :link="section.link"/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import bgCircle from "@/components/BgCircle.vue";
	import baseLink from "@/components/BaseLink.vue";
	import prosList from "@/components/ProsList.vue";
	import imageMock from "@/components/ImageMock.vue";

	export default {
		name: "DefaultSection",
		props: {
			section: Object
		},
		components: {
			prosList,
			baseLink,
			bgCircle,
			imageMock
		},
		computed: {
			sectionClasses() {
				return {
					'default-section--inverse': this.section.mode == 'inverse',
					...this.$responsive
				}
			},
			mediaType() {
				return this.section.media.type;
			}
		},
		methods: {
			getVideoSrc() {
				return "https://player.vimeo.com/video/" + this.section.media.video;
			}
		}
	};
</script>

<style scoped>

.default-section {
	display: flex;
	align-items: center;
	min-height: 100vh;
	/*
	background-image: url(../assets/texture-01.png);
	background-position: 0 0;
	*/
}

.default-section.mobile {
	flex-direction: column;
	padding: 15vh 0;
}

.default-section.tablet {
	flex-direction: column;
}

.default-section--inverse {
	flex-flow: row-reverse;
}

.media {
	width: 66.66%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-shrink: 0;
}

.sm-view .media {
	width: 100%;
	margin-bottom: 2.25rem;
}

.media__image {
	display: block;
	width: 90%;
}

.mobile .media__image {
	width: 100%;
}

.tablet .media__image {
	width: 60%;
}

.content {
	flex-grow: auto;
	text-align: left;
}

.tablet .content {
	width: 80%;
}

.content__legend {
	font-weight: 600;
	font-style: normal;
	font-size: .785rem;
	line-height: 1;
	letter-spacing: .275rem;
	color: var(--secondary-color);
	text-transform: uppercase;
}

.content__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.content__description {
	font-weight: 400;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.6875;
	color: var(--secondary-color);
}

.tablet .content__description {
	font-size: 1.25em;
}

.default-section__pros {
	margin: 0 0 1rem;
	color: var(--secondary-color);
}

.media__video {
	display: block;
	width: 80%;
	overflow: hidden;
}

.mobile .media__video {
	width: 100%;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.media__image, 
.media__mockup {
	transition: transform .3s ease;
}

.media:hover .media__image,
.media:hover .media__mockup {
	transform: scale(1.0125);
}

.media__mockup {
	width: 100%;
	max-width: 560px;
}
</style>
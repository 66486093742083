import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import metaHandler from "@/meta";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/company",
    component: () => import("../views/CompanyIndex.vue"),
    children: [
      {
        path: "",
        name: "company",
        component: () => import(/* webpackChunkName: "company" */ "../views/Company.vue")
      },
      {
        path: "about-zione",
        name: "about",
        component: () => import(/* webpackChunkName: "company" */ "../views/About.vue")
      },
      {
        path: "tech",
        name: "tech",
        component: () => import(/* webpackChunkName: "company" */ "../views/Tech.vue")
      },
      {
        path: "join-us",
        name: "joinUs",
        component: () => import(/* webpackChunkName: "company" */ "../views/JoinUs.vue")
      },
      {
        path: "apply",
        name: "apply",
        component: () => import(/* webpackChunkName: "apply" */ "../views/Apply.vue")
      },
      {
        path: "logo",
        name: "logo",
        component: () => import(/* webpackChunkName: "our-logo" */ "../views/OurLogo.vue")
      },
    ]
  },
  {
    path: "/productos",
    component: () => import("../views/ProductsIndex.vue"),
    children: [
      {
        path: "",
        name: "products",
        component: () => import(/* webpackChunkName: "products" */ "../views/Products.vue")
      },
      {
        path: "torneos",
        name: "torneos",
        component: () => import(/* webpackChunkName: "products" */ "../views/Torneos.vue")
      },
      {
        path: "coaches",
        name: "coaches",
        component: () => import(/* webpackChunkName: "products" */ "../views/Coaches.vue")
      },
      {
        path: "saldos",
        name: "saldos",
        component: () => import(/* webpackChunkName: "products" */ "../views/Saldos.vue")
      },
      {
        path: "apps",
        name: "apps",
        component: () => import(/* webpackChunkName: "products" */ "../views/Apps.vue")
      },
      {
        path: "apps/premium",
        name: "appPremium",
        component: () => import(/* webpackChunkName: "products" */ "../views/AppPremium.vue")
      },
      {
        path: "docs",
        name: "docs",
        component: () => import(/* webpackChunkName: "products" */ "../views/Docs.vue")
      }
    ]
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () => import(/* webpackChunkName: "contact-us" */ "../views/ContactUs.vue")
  },
  {
    path: "/thank-you",
    name: "thankYou",
    component: () => import(/* webpackChunkName: "thank-you" */ "../views/ThankYou.vue"),
    props: route => ({ name: route.query.name })
  },
  {
    path: "/frequent-questions",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/FrequentQuestions.vue")
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue")
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import(/* webpackChunkName: "signup" */ "../views/Signup.vue")
  },
  {
    path: "/settings/saldos",
    name: "saldosSettings",
    component: () => import(/* webpackChunkName: "settings-saldos" */ "../views/SaldosSettings.vue")
  },
  {
    path: "/account/billing",
    name: "billingInfo",
    component: () => import(/* webpackChunkName: "billing-info" */ "../views/BillingInfo.vue")
  },
  {
    path: "/404/:pathMatch(.*)*",
    alias: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue")
  }
];


/*
https://es.vuejs.org/v2/guide/routing.html
https://router.vuejs.org/guide/advanced/scroll-behavior.html
https://next.router.vuejs.org/guide/migration/#breaking-changes *** /Vue3
*/

const router = createRouter({
  //mode: "history", // not for vue3
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return { selector: to.hash };
      }
    }
    return { top: 0, left: 0 };
  }
});

router.beforeResolve(metaHandler);

export default router;
<template>
	<div class="next-section">
		<router-link class="next-section__link" :to="{ name: 'home'}">
			<div class="next-section__pretitle">siguiente sección</div>
			<h2 class="next-section__title">{{ section.name }}</h2>
		</router-link>	
		<p class="next-section__summary">{{ section.summary }}</p>
	</div>
</template>

<script>
	export default {
		name: "NextSection",
		props: [ 'section' ]
	}
</script>

<style scoped>

.next-section {
	margin-top: 10rem;
}

.next-section__link {
	text-decoration: none;
	color: initial;
}

.next-section__pretitle {
	color: var(--primary-color);
	text-transform: uppercase;
	font-size: .875rem;
	font-weight: 600;
}

.next-section__title {
	font-weight: 400;
	margin: .25rem 0 .5rem;
	opacity: .5;
	letter-spacing: -0.066rem;
	font-size: 1.875rem
}

.next-section__summary {
	margin: 0;
	color: var(--secondary-color);
}
</style>
<template>
	<section class="page-section">
		<div class="quote-section" :class="$responsive">
			<base-quote :author="section.author" :photo="section.authorPhoto">
				{{ section.content }}
			</base-quote>
		</div>
	</section>
</template>

<script>
	import baseQuote from "@/components/BaseQuote.vue";
	export default {
		name: "QuoteSection",
		components: {
			baseQuote
		},
		props: {
			section: Object
		}
	};
</script>

<style scoped>
.quote-section {
	min-height: 60vh;
	padding: 12.5vh 0;
	margin: 0 auto;
	display: flex;
	align-items: center;
	font-size: 1.25rem;
}

.quote-section.desktop {
	max-width: 80%;
}

.quote-section.mobile {
	padding: 7.5vh 0 10vh;
}
</style>
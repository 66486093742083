<template>
	<ul class="pros-list">
		<li class="pros-list__item" v-for="(item, index) in items" :key="index">
			<fa-icon :icon="['fas', 'check']" class="pros-list__icon" /> {{ item }}
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<style scoped>

	.pros-list {
		width: 100%;
		font-size: .875rem;
		padding: .5rem;
	}

	.pros-list__item {
		text-align: left;
		padding: .25em 0;
		color: inherit;
	}

	.pros-list__icon {
		margin-right: .5em;
		color: var(--primary-color);
		font-size: .9em;
	}

</style>
<template>
	<div class="bg-circle" :style="circleStyles"></div>
</template>

<script>
	export default {
		name: "BgCircle",
		props: [ 'circle' ],
		computed: {
			circleStyles() {
				const circle = this.circle;
				if (circle) {
					return {
						'width': circle.size,
						'height': circle.size,
						'top': circle.pos.top || 'auto',
						'bottom': circle.pos.bottom || 'auto',
						'left': circle.pos.left || 'auto',
						'right': circle.pos.right || 'auto'
					}
				}
				return {};	
			}
		}
	}
</script>

<style>
.bg-circle {
	position: absolute;
	background-color: #ffffff;
	border-radius: 50%;
	z-index: -1;
	height: 300px;
	width: 300px;
}

.bg-circle--inside {
	top: 0;
	bottom: 0;
	left: 20%;
	margin-top: 8.5%;
	width: 455px;
	height: 455px;
}

.bg-circle--left {
	width: 1374px;
	height: 1374px;
	top: -250px;
	left: -920px;
}
</style>
<template>
	<router-link class="base-link" :to="linkTo">
		{{ link.text }}
		<svg width="11" height="11" viewBox="0 0 11 11" fill="none" v-if="showArrow">
			<path class="base-link__arrow" d="M5.22513 10.0231L9.52311 5.72513L5.29797 1.5" stroke-width="1.5" stroke-linecap="round"></path>
		</svg>
	</router-link>
</template>

<script>
	export default {
		name: "BaseLink",
		props: {
			link: { type: Object }
		},
		computed: {
			linkTo() {
				const routeName = this.link.routeName;
				return routeName ? { name: routeName } : { to: 'home'};
			},
			showArrow() {
				return this.link.hideArrow ? false : true;
			}	
		}
	}
</script>

<style scoped>
.base-link {
  font-family: 'open sans', Helvetica;
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 600;
}

.base-link:hover {
  text-decoration: underline;
  /*filter: brightness(90%);*/
}

.base-link__arrow {
	stroke: var(--primary-color);
}
</style>
<template>
	<section class="page-section">
		<div class="product" :class="$responsive">
			<div class="product__wrapper">
				<h2 class="product__title" v-if="section.title" v-markdown:title>
					{{ section.title }}
				</h2>
				<p class="product__description" v-if="section.description">
					{{ section.description }}
				</p>
				<div class="product__grid">
					<div class="features" v-if="!isSingleColumn">
						<div class="feature" v-for="(feature, index) in features1" :key="index">
							<text-box :textbox="feature"></text-box>
						</div>	
					</div>
					<div class="product__image">
						<img :src="require(`@/assets/${section.image}`)">
					</div>
					<div class="features" :class="{'features--single': isSingleColumn}">
						<div class="feature" v-for="(feature, index) in features2" :key="index">
							<text-box :textbox="feature"></text-box>
						</div>	
					</div>
				</div>
			</div>	
		</div>
	</section>
</template>

<script>
	import textBox from "@/components/TextBox.vue"
	export default {
		name: "ProductSection",
		props: {
			section: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			textBox
		},	
		computed: {
			isSingleColumn() {
				return this.section.mode === 'single-column';
			},
			features1() {
				return this.section.features.filter((feature, index) => index % 2 === 0);
			},
			features2() {
				return this.section.features.filter((feature, index) => this.isSingleColumn || index % 2 === 1); 
			}
		}
	}	
</script>

<style scoped>

.product {
	display: flex;
	align-items: center;
	min-height: 100vh;
	padding-bottom: 6rem;
}

.product__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.product__description {
	max-width: 800px;
	font-weight: 400;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.6875;
	color: var(--secondary-color);
	margin: 0 auto 1rem;
}

.product__grid {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 4rem;
}

.mobile .product__grid {
	flex-wrap: wrap;
}

.mobile .product__grid > * {
	flex-basis: 100%;
}

.features {
	flex-basis: 30%;
	display: flex;
	flex-wrap: wrap;
	align-content: space-between;
}

.features--single {
	flex-basis: 60%;
}

.features > * {
	flex-basis: 100%;
	margin-bottom: 2.25rem;
}

.mobile .features > * {
	margin-bottom: 4.25rem;
}

.product__image {
	flex-basis: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.product__image img {
	max-width: 100%;
}

.mobile .product__image img {
	max-height: 66vh;
	margin-bottom: 2.25rem;
}
</style>
<template>
	<div class="doit-now" ref="btn">
		¿ Qué esperas?
		<router-link class="doit-now__btn" :to="{ name: 'contactUs'}">
			¡Comienza Ahora!
		</router-link>
	</div>
</template>

<script>
	import { onMounted, ref } from 'vue';
	import { hexToRgb } from "@/utils.js";
	export default {
		name: 'DoItNowBtn',
		setup() {
			const btn = ref(null);
			onMounted(() => {
				const priColor = hexToRgb(getComputedStyle(document.body).getPropertyValue("--primary-color").trim());
				btn.value.style.setProperty('--shadow-color', `rgba(${ priColor.r }, ${ priColor.g }, ${ priColor.b }, .26)`);
    	});
    	return { btn }
		}
	}
</script>

<style scoped>
	.doit-now {
		margin-top: 2rem;
		color: var(--secondary-color);
		display: flex;
		flex-flow: column;
		align-items: center;
	}

	.doit-now__btn {
		display: inline-block;
		background-color: var(--primary-color);
		color: var(--foreground-text-color);
		font-size: 1.125rem;
		font-weight: 600;
		padding: 1em 3em;
		margin-top: 1.125rem;
		border-radius: 2rem;
		text-decoration: none;
		transition: transform .3s ease;
		overflow: hidden;
		box-shadow: 10px 16px 40px 0 var(--shadow-color, rgba(0, 0, 0, 0.10));
	}

	.doit-now__btn:hover {
		transform: translateY(-.125rem);
	}
</style>
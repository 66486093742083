<template>
	<section class="page-section">
		<div class="single-section">
			<h2 class="single-section__title" v-markdown:title>
				{{ section.title }}
			</h2>
			<p class="single-section__content">
				{{ section.content }}
			</p>
			<children-handler v-for="(item, index) in section.children" :key="index" :children="item"></children-handler>
		</div>
	</section>	
</template>

<script>
	import childrenHandler from "@/components/ChildrenHandler.vue";

	export default {
		name: "SingleSection",
		props: {
			section: Object
		},
		components: {
			childrenHandler
		}
	}
</script>

<style scoped>
.single-section {
	display: flex;
	flex-flow: column;
	justify-content: center;
	min-height: 60vh;
}

.single-section__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.single-section__content {
	max-width: 800px;
	font-weight: 400;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.6875;
	color: var(--secondary-color);
	margin: 0 auto 1rem;
}

.children-handler {
	margin: .25rem 0 0;
}
</style>
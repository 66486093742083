// zione responsive plugin by CuR Jan 2021
import { reactive } from 'vue'

function onResize() {
  let viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (viewportWidth < 640) {
    setBreakpoint('mobile');
  } else if (viewportWidth >= 640 && viewportWidth <= 990) {
     setBreakpoint('tablet'); 
  } else {
    setBreakpoint('desktop');
  }
}

function onMediaChange() {
  // breakpoints
  breakpoint('screen and (max-width: 640px)', 'mobile');
  breakpoint('screen and (min-width: 641px) and (max-width: 990px)', 'tablet');
  breakpoint('screen and (min-width: 991px)', 'desktop'); 

  function breakpoint(query, device) {
    const mediaChange = mql => { if (mql.matches) setBreakpoint(device) };
    const mq = window.matchMedia(query);
    if (mq.addEventListener) {
      mq.addEventListener('change', mediaChange); 
    } else {
      mq.addListener(mediaChange); // old iOS / safari failover
    }
    mediaChange(mq);
  };
}

function setBreakpoint(device) { //console.log('setBreakpoint', device);
  responsive.mobile = device === 'mobile';
  responsive.tablet = device === 'tablet';
  responsive.desktop = device === 'desktop';
  responsive["sm-view"] = responsive.mobile || responsive.tablet;
}

const responsive = reactive({
  mobile: false,
  tablet: false,
  desktop: false,
  "sm-view": false 
});

export default {
  install: app => {
    if (window.matchMedia) {
      onMediaChange();
    } else {
      window.addEventListener('resize', onResize, { passive: true });
      onResize();
    }
    app.config.globalProperties.$responsive = responsive;
  }
}
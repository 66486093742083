<template>
	<div class="slide">
		<nav class="slide__header">
				<span class="slide__close" @click="close">
					<fa-icon :icon="['fas', 'times']" />	
				</span>
		</nav>
		<section class="slide__content">
				<ul class="slide__items">
					<li class="slide__item" v-for="(item, index) in menuItems" :key="index">
						<router-link :to="{ name: item.routeName }" class="router__link" @click="close">
							{{ item.label }}
						</router-link>
					</li>
				</ul>
		</section>
		<div class="slide__footer">
			<zionesport-icon></zionesport-icon>
		</div>
	</div>
</template>

<script>
	import zionesportIcon from "@/components/ZionesportIcon.vue";
	export default {
		name: "MobileMenuSlide",
		components: {
			zionesportIcon
		},
		data() {
			return {
				menuItems: [
					{label: "Inicio", routeName: "home"},
					{label: "Productos", routeName: "products"},
					{label: "Nosotros", routeName: "company"},
					{label: "Contacto", routeName: "contactUs"}
				]
			}
		},
		methods: {
			close() {
				this.$emit('close');
			}
		}
	}
</script>

<style scoped>
.slide {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	backdrop-filter: saturate(180%) blur(20px);
	background-color: rgba(255,255,255,0.82);
	z-index: 101;
	display: flex;
	flex-direction: column;
}

.slide__header {
	height: var(--nav-height);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 2rem;
}

.slide__close {
	font-size: 1.25rem;
	color: var(--secondary-color);
}

.slide__content {
	/*height: calc(100vh - var(--nav-height) - 28px);*/
	padding-top: 12.5vh;
}

.slide__items {
	width: 100vw;
	padding: var(--mobile-padding);
}

.slide__item {
	padding: 1.2rem 0;
	font-size: 1.33rem;
}

.router__link {
	text-decoration: none;
	color: var(--secondary-color);
}

.router-link-active {
	color: var(--primary-color);
}

.slide__footer {
	/*position: fixed;*/
	/*bottom: 1.5rem;*/
	width: 100vw;
	display: flex;
	justify-content: center;
	margin: auto 0 4.175rem;
}

.zionesport-svg-icon {
	width: 28px;
}
</style>
<template>
	<div class="quote">
		<div>
			<p class="quote__content" v-markdown:title>
				<slot></slot>
			</p>
			<div class="quote-author" v-if="author">
				<div class="quote-author__photo" v-if="!!photo">
					<img :src="require(`@/assets/${photo}`)">
				</div>
				<div class="quote-author__name">
					{{ author }}
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
	export default {
		name: "BaseQuote",
		props: {
			author: {
				type: String,
				default: ""
			},
			photo: { type: String }
		}
	}
</script>

<style scoped>

.quote {
	position: relative;
	font-size: inherit;
	padding: 0 .25rem;
	margin-top: 2rem;
	padding-left: .66em;
}

.quote::before {
	content: open-quote;
	font-family: serif;
	color: var(--primary-color);
	font-size: 4.25em;
	font-weight: 900;
	position: absolute;
	z-index: -1;
	top: -0.25em;
	left: -0.125em;
}

.quote__content {
	color: var(--secondary-color);
	font-size: 1em;
	font-style: italic;
	text-align: left;
	margin: 0;
	line-height: 1.75em;
	text-indent: 1.5em;
}

.quote-author {
	margin-top: 1.5em;
	display: flex;
	align-items: center;
	column-gap: .66em;
}

.quote-author__photo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42px;
}

.quote-author__photo > img {
	height: 100%;
	width: auto;
}

.quote-author__name {
	font-size: .9375rem;
	text-align: right;
	color: var(--secondary-color);
	font-weight: 600;
}
</style>
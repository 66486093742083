<template>
	<div class="text-box" :class="$responsive">
		<div class="text-box__icon" v-if="hasIcon">
			<img :src="require(`@/assets/${ textbox.icon }`)">
		</div>
		<div class="text-box__wrapper">
			<h3 class="text-box__title">
				{{ textbox.title }}
			</h3>
			<p class="text-box__content" v-for="(paragraph, index) in content" :key="index">
				<span v-markdown:text>{{ paragraph }}</span>
			</p>
			<div class="text-box__pros" v-if="textbox.pros">
				<pros-list :items="textbox.pros"></pros-list>
			</div>
			<div class="text-box__link" v-if="textbox.link">
				<base-link :link="textbox.link"/>
			</div>
		</div>
	</div>
</template>

<script>
	import baseLink from "@/components/BaseLink";
	import prosList from "@/components/ProsList.vue";

	export default {
		name: "TextBox",
		props: {
			textbox: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			baseLink,
			prosList
		},
		computed: {
			hasIcon() {
				return (this.textbox.icon) ? true : false;
			},
			content() {
				const content = this.textbox.content;
				return Array.isArray(content) ? content : [content];
			}
		}
	}
</script>

<style scoped>
.text-box {
	display: flex;
	width: 100%;
	height: 100%;
	text-align: left;
}

.text-box.sm-view {
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;
}

.text-box__wrapper {
	flex-basis: 100%;
}

.text-box__icon {
	position: relative;
	flex: 0 0 auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0 1.625rem 0 0;
	font-size: 1rem;
	width: 3em;
	height: 3em;
}

.sm-view .text-box__icon {
	margin: 0 .24em 2rem 0;
	font-size: 1.33rem;
	align-items: center;
}

.mobile .text-box__icon {
	font-size: 1.75rem;
}

.text-box__icon::after {
	content: '';
	position: absolute;
	background-color: var(--complementary-color);
	width: 76%;
	height: 76%;
	z-index: -1;
	border-radius: .825em;
	bottom: 0;
	right: 0;
	opacity: .125;
}

.text-box__icon > img {
	max-width: 95%;
	max-height: 95%;
}

.text-box__title {
	margin: 0;
	font-size: 1.625rem;
	letter-spacing: -0.066rem;
}

.text-box__content {
	margin: 1rem 0 0;
	line-height: 1.825;
	color: var(--secondary-color);
}

.sm-view .text-box__content {
	text-align: left;
	width: 87.5%;
	margin: 1rem auto 0;
}

.text-box__link {
	margin: 1.625rem auto 0;
}

.sm-view .text-box__link {
	text-align: left;
	width: 87.5%;
}

.text-box__pros {
	margin: .75rem 0 0;
	color: var(--secondary-color);
}

.sm-view .text-box__pros {
	width: 87.5%;
	margin: .75rem auto 0;
}
</style>
<template>
	<section class="page-section">
		<div class="sports-section" :class="$responsive">
			<div class="viewport-frame">
				<h2 class="sports-section__title">{{ section.title }}</h2>
				<p class="sports-section__content">
					{{ section.content }}
					<span class="sports-section__demo-msg">
						haz clic en un deporte para abrir su demo
					</span>
				</p>
				<div class="sports-grid">
					<div class="sport unselectable" v-for="(sport, sportIndex ) in section.sports" :key="sportIndex" :style="styles(sportIndex)">
						<div class="sport__image">
							<img :src="require(`@/assets/${sport.image}`)" />
						</div>
						<div class="sport__name">
							{{ sport.name }}
						</div>
						<div v-if="sport.demoUrl.length" class="explore-demo" @click="exploreDemo(sportIndex)">
							Demo &nbsp;&nbsp;<fa-icon :icon="['fas', 'arrow-right']" size="sm" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: "Sports",
		props: {
			section: Object
		},
		methods: {
			styles(index) {
				const palette = [
					['#f44881', '#ec454f'],
					['#F38F2E', '#F16329'],
					['#ffc73c', '#f19a1a'],
					['#6edcc4', '#1aab8b'],
					['#21c8f6', '#637bff'],	
					['#b372bd', '#8b60ed']
				]
				return {
					'background': `linear-gradient(180deg, ${palette[index][0]}, ${palette[index][1]})`
				}
			},
			exploreDemo(index) {
				const url = this.section.sports[index].demoUrl;
				window.open(url, '_blank');
			}
		}
	}
</script>

<style scoped>

.sports-section {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
}

.sports-section.mobile {
	padding: 15vh 0;
}

.sports-section__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
	margin: 0;
}

.sports-section__content {
	padding: 0 2rem;
	font-weight: 400;
	font-style: normal;
	font-size: 1.125rem;
	line-height: 1.6875;
	color: var(--secondary-color);
}

.sports-section__demo-msg {
	display: block;
	font-size: .825em;
	margin-top: .3125rem;
	color: var(--primary-color);
}

.sports-grid {
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around; /*safari failover*/
	justify-content: space-evenly;
}

.tablet .sports-grid {
	max-width: calc(120px * 3 + 7rem);
	margin-left: auto;
	margin-right: auto;
}

.sport {
	position: relative;
	width: 120px;
	height: 120px;
	border-radius: .9rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}

.sm-view .sport {
	margin-bottom: 3.75rem;	
}

.sport__image {
	height: 70%;
	width: auto;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform .25s ease-out;
}

.sport__image > img {
	height: 100%;
}

.sport:hover .sport__image {
	transform: rotate(180deg);
}

.sport__name {
	color: #ffffff;
	font-size: 1rem;
}

.explore-demo {
	position: absolute;
	bottom: -35%;
	background-color: var(--primary-color);
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	color: #ffffff;
	font-size: .875rem;
	padding: .33em 0;
	width: 100%;
	border-radius: 2rem;
	opacity: 0;
	transition: all .75s ease;
	cursor: pointer;
}

.sport:hover .explore-demo {
	opacity: 1;
	bottom: -30%;
}
</style>
<template>
	<svg viewBox="0 0 120 120" class="zionesport-svg-icon">
		<path class="st0" d="M18.9,47.15c0,0,131.17-14.7,69.01,50.32c-62.15,65.02,70.23-57.65-59.9-44.62"/>
		<path class="st0" d="M98.23,78.28c0,0-131.99-0.41-62.8-57.89c69.19-57.48-76.37,49.24,54.4,51.19"/>
	</svg>
</template>

<style scoped>
	.zionesport-svg-icon {
		fill: var(--primary-color);
	}
</style>


<template>
	<svg viewBox="0 0 454.37 120" class="zionesport-svg-logo" :style="svgColor">
		<g class="zionesport-svg-logo__icon">
			<path d="M22.48,47.15c0,0,131.17-14.7,69.01,50.32c-62.15,65.02,70.23-57.65-59.9-44.62"/>
			<path d="M101.81,78.28c0,0-131.99-0.41-62.8-57.89C108.2-37.1-37.36,69.62,93.4,71.57"/>
		</g>
		<g class="zionesport-svg-logo__zione">
			<path d="M119.4,54.54h28.48v7.38L129,84.43h18.18v7.03H118.7v-7.03l19.16-22.5H119.4V54.54z"/>
			<path d="M160.1,41.18v7.38h-9.32v-7.38H160.1z M160.1,54.54v36.91h-9.32V54.54H160.1z"/>
			<path d="M163,73c0-12.54,6.21-18.81,18.63-18.81c12.42,0,18.63,6.27,18.63,18.81c0,12.52-6.21,18.77-18.63,18.77
				C169.26,91.77,163.05,85.52,163,73z M181.64,84.43c6.21,0,9.32-3.86,9.32-11.57c0-7.52-3.11-11.29-9.32-11.29
				c-6.21,0-9.32,3.76-9.32,11.29C172.32,80.57,175.43,84.43,181.64,84.43z"/>
			<path d="M203.17,91.46V54.54h7.21l1.16,4.71c4.48-3.14,8.98-4.71,13.5-4.71c8.51,0,12.76,4.58,12.76,13.75v23.17
				h-9.32V68.18c0-4.08-2.03-6.12-6.08-6.12c-3.26,0-6.56,1.56-9.91,4.68v24.71H203.17z"/>
			<path d="M274.18,76.62h-24.15c0,4.97,4.05,7.45,12.16,7.45c3.73,0,7.45-0.35,11.18-1.05v7.38
				c-3.26,0.7-7.45,1.05-12.59,1.05c-13.38,0-20.07-6.29-20.07-18.88c0-12.02,6.13-18.04,18.39-18.04
				C271.33,54.54,276.36,61.9,274.18,76.62z M250.02,69.8h15.4c0.33-5.3-2.13-7.95-7.38-7.95C253.12,61.85,250.45,64.5,250.02,69.8z"/>
			<path d="M286.01,82.84v8.61h-9.32v-8.61H286.01z"/>
		</g>
		<g class="zionesport-svg-logo__sport" :class="sportTextColor">	
			<path d="M291.2,89.7v-5.62c4.1,1.41,8.37,2.11,12.8,2.11c5.88,0,8.82-1.76,8.82-5.27c0-3.28-1.93-4.92-5.8-4.92h-5.98
			c-7.73,0-11.6-3.63-11.6-10.9c0-7.03,5.12-10.55,15.36-10.55c4.41,0,8.66,0.59,12.76,1.76v5.62c-4.1-1.41-8.36-2.11-12.76-2.11
			c-6.14,0-9.21,1.76-9.21,5.27c0,3.28,1.82,4.92,5.45,4.92h5.98c8.2,0,12.3,3.63,12.3,10.9c0,7.03-5.11,10.55-15.33,10.55C299.57,91.46,295.3,90.87,291.2,89.7z"/>
			<path d="M324.51,56.05c4.9-1.01,9.96-1.51,15.19-1.51c11.84,0,17.75,5.85,17.75,17.54c0,12.91-5.95,19.37-17.86,19.37
				c-2.95,0-5.81-0.59-8.58-1.76v15.12h-6.5V56.05z M331.02,83.93c2.55,1.27,5.4,1.9,8.54,1.9c7.66,0,11.5-4.61,11.5-13.82
				c0-8.04-3.81-12.06-11.43-12.06c-3.28,0-6.15,0.28-8.61,0.84V83.93z"/>
			<path d="M360.89,73c0-12.3,5.86-18.46,17.58-18.46c11.72,0,17.58,6.15,17.58,18.46c0,12.28-5.86,18.42-17.58,18.42
				C366.79,91.42,360.93,85.28,360.89,73z M378.46,86.18c7.38,0,11.07-4.44,11.07-13.32c0-8.7-3.69-13.04-11.07-13.04
				c-7.38,0-11.07,4.35-11.07,13.04C367.39,81.74,371.08,86.18,378.46,86.18z"/>
			<path d="M401.23,91.46V54.54h4.39l1.16,4.71c3.23-3.14,7.12-4.71,11.67-4.71v5.41c-4.41,0-7.98,1.56-10.72,4.68v26.82H401.23z"/>
			<path d="M421.36,48.57h4.08l1.41,5.98h8.58v5.27h-7.56v21.2c0,3.45,1.49,5.17,4.46,5.17h3.09v5.27h-4.78c-6.19,0-9.28-3.32-9.28-9.95V48.57z"/>
		</g>
	</svg>
</template>

<script>
	export default {
		props: {
			color: {
				type: String,
				default: null
			}
		},
		computed: {
			svgColor() {
				return { "fill": this.color || '#333' }
			},
			sportTextColor() {
				return { "set-pripary-color": !this.color }
			}
		}
	}
</script>

<style scoped>
	.set-pripary-color {
		fill: var(--primary-color);
	}
</style>
<template>
	<section class="page-section">
		<div class="get-started-section" :class="$responsive">
			<div class="viewport-frame">
				<h2 class="get-started__title">
					Comienza ahora en
					<br><span class="text--highlighted">{{ stepsCount }}</span> sencillos <span class="text--highlighted">pasos</span>
				</h2>
				<div class="steps-grid">
					<div class="step" v-for="(step, stepIndex ) in section.steps" :key="stepIndex">
						<div class="step__number">{{ stepIndex + 1 }}</div>
						<h3 class="step__title">{{ step.title }}</h3>
						<p class="step__description">
							{{ step.description }}
						</p>
					</div>
				</div>
				<getStartedBtn/>
			</div>
		</div>
	</section>
</template>

<script>
	import getStartedBtn from "@/components/GetStartedBtn.vue";
	export default {
		name: "GetStartedSteps",
		props: {
			section: Object
		},
		components: {
			getStartedBtn
		},
		computed: {
			stepsCount() {
				return this.section.steps.length;
			}
		}
	}
</script>

<style scoped>

.get-started-section {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
}

.get-started-section.mobile {
	padding-bottom: 17.5vh;
}

.get-started__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;
}

.steps-grid {
	display: flex;
	justify-content: space-between;
	margin-top: 4rem;
}

.sm-view .steps-grid {
	flex-wrap: wrap;
}

.step {
	flex-basis: 0;
	flex-grow: 1;
}

.mobile .step {
	flex-basis: 100%;
	padding-bottom: 4rem;
}

.tablet .step {
	flex-basis: 40%;
	padding-bottom: 4rem;
	font-size: 1.125em;
}

.step__number {
	width: 2.825em;
	height: 2.825em;
	background-color: var(--primary-color);
	border-radius: 50%;
	margin: 0 auto;
	border: solid 1px #e6e7e8;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.33rem;
	color: var(--foreground-text-color);
	font-weight: 600;
}

.step__title {
	font-weight: 400;
}

.step__description {
	color: var(--secondary-color);
}

.sm-view .step__description {
	width: 75%;
	margin: 0 auto;
}
</style>
<template>
	<div class="clients-section" :class="$responsive">
		<div class="page-section">
			<h2 class="clients__title">
				Quiénes utilizan zione.<span class="text--highlighted">sport</span>
			</h2>
			<div class="description">
					Las mejores ligas, copas e instituciones deportivas de México, utilizan <b>zione.sport</b> para administrar sus torneos.
			</div>
			<div class="clients__grid">
				<div class="clients__item" v-for="(logo, index) in clients" :key="index">
						<img :src="require(`../assets/${logo}`)"/>
				</div>
			</div>
		</div>	
	</div>
</template>

<script>
	export default {
		name: "OurClients",
		data() {
			return {
				clients: ['cte-cordica.svg', 'cte-copa-santos-v.png', 'cte-scyf.svg', 'cte-tdla-2022.svg', 'cte-forca-new.svg', 'cte-copa-mazatlan-2.svg', 'cte-futsat.svg', 'cte-futcenter.svg', 'cte-masports.svg', 'cte-rancho-seco.png', 'cte-albatros-soccer-2.svg', 'cte-gametime-league.png', 'cte-corner-sport.svg', 'cte-fantasy-flag-football.png', 'cte-oefa.png', 'cte-copa-bajio.png', 'cte-fan-soccer.svg', 'cte-youth-soccer-cup.png', 'cte-copa-maya.png', 'cte-copa-franja.svg', 'cte-copa-g.svg', 'cte-mty-cup.svg']
			}
		}
	}
</script>

<style scoped>

.clients__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;		
}

.clients__grid {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	justify-content: space-evenly;
	--col-gap: 2rem;
}

.sm-view .clients__grid {
	width: 100%;
}

.clients__item {
	width: calc(17.5% + var(--col-gap));
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
}

.sm-view .clients__item {
	width: calc(20% + var(--col-gap));
}

.clients__item > img {
	max-width: 95%;
	max-height: 5rem;
}
</style>